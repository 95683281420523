import React from 'react'
import { useSelector } from 'react-redux';
import { BuildingIcon } from '../icons';

function UserDashboard() {
  const getGreeting = () => {
    const currentTime = new Date().getHours();
    let greeting = '';
    if (currentTime >= 5 && currentTime < 12) {
      greeting = 'Good Morning ☀️';
    } else if (currentTime >= 12 && currentTime < 18) {
      greeting = 'Good Afternoon 🌤️';
    } else {
      greeting = 'Good Evening 🌙';
    }
    return greeting;
  };
  const userInfo = useSelector((state) => state?.user?.user?.user);
  console.log(userInfo)

  return (
    <div className='w-full flex flex-col'>
      <p className="text-xs text-white">
        {getGreeting()} <span className="font-semibold">{userInfo ? userInfo.email : ' '},</span>
      </p>
      <p className='text-white text-2xl font-bold'>Welcome to Freedom Bot</p>
      <div className='w-[60%] mx-auto glass_morphism p-6 my-8 animate-pulse'>
        <p className='text-white text-lg font-semibold text-center'>Admin Dashboard Portal still under construction</p>
        <div className='w-full flex justify-center items-center'>
          <BuildingIcon />
        </div>
      </div>
    </div>
  )
}

export default UserDashboard