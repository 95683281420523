import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isLoggedIn: false,
    user: null,
    accountInfo: null,
    botRunning: false,
    apiKey: null,
    apiSecretKey: null,
    exchangeType: 'binanceFutures',
    connected: false,
    accountId: null,
    tradeDecision: null,
    futuresAssets: [],
    tradingPairs: [],
    orderType: 'market',
    selectedPair: 'KSMUSDT',
    showAccountInfo: false,
    orderTypes: ['market', 'limit', 'trailing'],
    tradeResult: null,
    tradeResultVisible: false,
    symbol: '',
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, { payload }) => {
            state.isLoggedIn = true;
            console.log(payload, "from redux=")
            state.user = { ...payload };
            state.apiKey = "";
            state.apiSecretKey = "";



            console.log(state.user, "from redux=")

        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.user = null;
            state.apiKey = null;
            state.apiSecretKey = null;

        },
        connectExchanger: (state, { payload }) => {
            state.connected = true;
            state.accountId = payload.accountId;

            console.log(payload)
        }
        ,
        disconnectExchanger: (state) => {
            state.connected = false;
            state.accountId = null;
        }
        ,
        fetchAccountInfo: (state, { payload }) => {
            state.accountInfo = payload.usdtBalance;
            console.log(payload)
        },
        handleApiKeyChanges: (state, { payload }) => {
            state.apiKey = payload.apiKey;
            state.apiSecretKey = payload.apiSecretKey;
            console.log(payload)
        }
        ,
        greet: (state) => {
            console.log("here is the initial state", state)
        }
    }
})
export const { greet, login, logout, disconnectExchanger, connectExchanger, handleApiKeyChanges } = userSlice.actions;
export default userSlice.reducer;