import { useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { IoEye } from "react-icons/io5";
import "../Navbar/navbar.css"
import botImage from "../../assets/freedom ui2-01.png"
import { Link } from "react-router-dom";
import './on-board.css'
import axios from "axios";
const OnBoardSignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState(false);
    const [confPasswordError, setConfPasswordError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);




    const handleShowPassword = () => setShowPassword((prev) => !prev);
    const handleShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);
    const handleRegisterUser = async (e) => {
        e.preventDefault();
        setError(false);
        setConfPasswordError(false);
        if (!email.trim() || !password.trim() || !confPassword.trim()) {
            setError(true);
            return;

        }
        if (
            password !== confPassword
        ) {
            setConfPasswordError(true);
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.post(process.env.NODE_ENV=='production'? "https://freedombot.online/user/create-new-user":"http://localhost:8001/user/create-new-user", { email, password });
            const createdUser = response.data;
            console.log("User created:", createdUser);
            return createdUser;
        }
        catch (error) {
            console.log(error);

        }
        finally {
            setIsLoading(false)

        }

    }

    return (
        <div className="bg-transparent w-full mt-8">
            <div className="w-[95%] md:w-[50%] xl:w-[40%] 2xl:w-[35%] mx-auto border-2 border-white p-8 rounded glass_morphism shadow-md flex flex-col gap-y-6 ">
                <p className="text-white flex justify-center  font-semibold">Create Account</p>
                <div className="flex flex-col gap-y-3">
                    {error && <p className="text-sm text-red-500 font-medium">Please fill all required fields</p>}
                    <div className="flex flex-col gap-y-1">
                        <p className="text-white text-sm font-normal text-start">Email</p>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} className="text-white border border-white p-1 px-4 outline-none bg-transparent rounded-md placeholder:text-white text-sm" type="email" placeholder="johndoe@gmail.com" />
                    </div>
                    <div className="flex flex-col gap-y-1">
                        <p className="text-white text-sm font-normal text-start">Password</p>
                        <div className="flex w-full items-center justify-between border p-1 pr-3  border-white rounded-md">
                            <input value={password} onChange={(e) => setPassword(e.target.value)} className="text-white  px-4 outline-none bg-transparent rounded-md placeholder:text-white text-sm w-[95%]" type={showPassword ? 'text' : 'password'} placeholder="*******" />
                            {showPassword ? <IoEye onClick={handleShowPassword} className="text-white text-md" /> : <FaEyeSlash onClick={handleShowPassword} className="text-white text-md" />}
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-1">
                        <p className="text-white text-sm font-normal text-start">Confirm Password</p>
                        <div className="flex w-full items-center justify-between border  p-1 pr-3  border-white rounded-md">
                            <input value={confPassword} onChange={(e) => setConfPassword(e.target.value)} className="text-white   px-4 outline-none bg-transparent  placeholder:text-white text-sm w-[95%]" type={showConfirmPassword ? 'text' : 'password'} placeholder="*******" />
                            {showConfirmPassword ? <IoEye onClick={handleShowConfirmPassword} className="text-white text-md" /> : <FaEyeSlash onClick={handleShowConfirmPassword} className="text-white text-md" />}

                        </div>
                        {confPasswordError && <p className="text-sm text-red-500 font-medium">Password mast match!</p>}
                    </div>

                    <p className="text-white text-sm font-semibold text-start pt-4">Already Have an account ? <span><Link className="text-[#ca217ebb] font-semibold" to="/login" >Log In</Link></span></p>
                </div>
                <button onClick={handleRegisterUser} className="text-[#ca217ebb] border  rounded-2xl bg-white w-1/3 mx-auto p-2 text-sm font-bold hover:cursor-pointer hover:bg-[#ca217ebb] hover:text-white hover:border-none transition duration-300">
                    {isLoading ? 'Loading ...' : 'Sign Up'}
                </button>

            </div>

        </div>
    )

}
export default OnBoardSignUp;