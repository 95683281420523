import React, { useState } from 'react';
import "./on-board.css"

const ContactSection = () => {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <div className="p-4 glass_morphism my-8 w-[90%] md:w-[40%] mx-auto">
            <h5
                id="contact-label"
                className="flex items-center justify-center mb-6 text-base font-semibold text-white"
            >
                <svg
                    className="w-4 h-4 mr-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 16"
                >
                    <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                    <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                </svg>
                Contact us
            </h5>

            <form className="">
                <div className="mb-2">
                    <p
                        className="text-white text-sm font-normal text-start"
                    >
                        Your email
                    </p>
                    <input
                        type="email"
                        id="email"
                        className="w-full my-2 text-white border border-white p-2.5 px-4 outline-none bg-transparent rounded-md placeholder:text-white text-sm"
                        placeholder="name@company.com"
                        required
                    />
                </div>

                <div className="mb-2">
                    <p
                        className="text-white text-sm font-normal text-start"
                    >
                        Subject
                    </p>
                    <input
                        type="text"
                        id="subject"
                        className="w-full my-2 text-white border border-white p-2.5 px-4 outline-none bg-transparent rounded-md placeholder:text-white text-sm"
                        placeholder="Let us know how we can help you"
                        required
                    />
                </div>

                <div className="mb-2">
                    <p
                        className="text-white text-sm font-normal text-start"
                    >
                        Your message
                    </p>
                    <textarea
                        id="message"
                        rows="4"
                        className="w-full my-2 text-white border border-white p-2.5 px-4 outline-none bg-transparent rounded-md placeholder:text-white text-sm"
                        placeholder="Your message..."
                    />
                </div>
                <div className='w-full flex justify-center mb-3'>


                    <button onClick={() => { }} className="text-[#ca217ebb] border  rounded-2xl bg-white md:w-1/3 mx-auto p-2 text-sm font-bold hover:cursor-pointer hover:bg-[#ca217ebb] hover:text-white hover:border-none transition duration-300">
                        {isLoading ? 'Loading ...' : 'Send Message'}
                    </button>
                </div>
            </form>
            {/* <div className='flex justify-center gap-x-4'>
                <p className="mb-2 text-sm text-white">
                    <a href="#" className="hover:underline">
                        freedmobot@gmail.com
                    </a>
                </p>
                <p className="text-sm text-white">
                    <a href="#" className="hover:underline">
                        +250 787703659
                    </a>
                </p>

            </div> */}

        </div>
    );
};

export default ContactSection;
