import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import "./checkout.css";
import { toast } from "react-toastify";
import { auth } from "../../../firebase";
import withAuth from "../../ProtectionHOCs/withAuth";
import "../on-board.css"
const CheckoutPage = () => {
  const [packageType, setPackageType] = useState("monthly");

  const [coinType, setCoinType] = useState("USDT.TRC20");
  const [isLoading,setIsLoading] = useState(false);
  const getPaymentUrl = () => {
    setIsLoading(true);
    try {
      auth.currentUser.getIdToken().then(async (token) => {
        const response = await fetch(
          process.env.NODE_ENV === "production" ?
            `https://freedombot.online/payment/create-checkout` : 'http://localhost:8001/payment/create-checkout',
          {
            method: "POST",
            body: JSON.stringify({
              packageType,
              coinType
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((response) => response.json());

        if (response.checkout_url) {
          window.open(response.checkout_url, "_blank");
        }
      });
    } catch (error) {
      console.error("Error getting payment url", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    toast.info("You should first pay to access the bot");
  }, []);


  return (
    <div className="h-full flex justify-center items-center">
      <div className="glass_morphism flex flex-col MD:justify-center w-[98%] md:w-[50%] h-[60%] md:h-[90%] my-auto mx-auto gap-y-2 md:gap-y-4 p-3 md:p-6 ">
        <h1 className="text-center font-semibold text-white py-2 md:py-8 text-xl">Checkout Page</h1>
        <div className="flex flex-col px-2 md:px-8 gap-y-2">
          <label className="text-white font-semibold">
            Selecy Your desired package:
          </label>
          <select className="text-white p-2 outline-none glass_morphism" value={packageType} onChange={(e) => setPackageType(e.target.value)}>
            <option value="monthly" >Monthly - 50 USDT</option>
            <option value="yearly">Yearly - 450 USDT</option>
          </select>
        </div>
        <div className="flex flex-col px-2 md:px-8 gap-y-2">
          <label className="text-white font-semibold">
            Currency:
          </label>
          <select className="text-white p-2 outline-none glass_morphism" value={coinType} onChange={(e) => setCoinType(e.target.value)}>
            <option value="USDT.TRC20">USDT.TRC20</option>
            <option value="BTC.BEP20">BTC.BEP20</option>
          </select>
        </div>
        
        <button className="bg-white rounded-xl text-major-text-style w-40 mx-auto font-semibold p-3" onClick={getPaymentUrl}>{isLoading?'Loading...': 'Process Payment'}</button>
      </div>
    </div>
  );
};


export default withAuth(CheckoutPage);
